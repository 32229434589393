import jsPDF from "jspdf";
import image from "./assests/WJ_AU.png";
import moment from "moment";
import config from "./config.json";
import "./Arial-bold";
import "./Arial-normal";

export const WebJetInvoicePDF = (invoiceDatas) => {
  var doc = new jsPDF("p", "pt");
  console.log(invoiceDatas);
  const fontType = "Arial";

  doc.setFont(fontType, "bold");
  doc.setFontSize(18.5);
  doc.text(10, 40, "Tax Invoice");
  doc.setFont(fontType, "bold");
  doc.setFontSize(9.5);
  const flightDetails = [];

  let j = 1;
  let index = 0;
  let position = 30;

  let total = 0.0;
  let totalGST = 0.0;
  let noADC = 0;
  let serviceFee = config.ServiceFee;
  let serviceGSTFee = config.ServiceGSTFee;
  let invoiceChangeFeeAmt = 0.0;
  let addditionalAmount = 0.0;
  let changeFeeAmount = 0.0;

  let fileName =
    invoiceDatas[0].WebjetReference +
    "(" +
    invoiceDatas[0].RecordLocator +
    ").pdf";
  const GenerateHeader = (doc, invoiceDatas) => {
    console.log(doc);
    var img = new Image();
    img.src = image;
    doc.addImage(img, "png", 460, 3);
    doc.setTextColor("red");
    doc.text(10, 40 + position, "Webjet Reference:");
    doc.text(110, 40 + position, invoiceDatas[0].WebjetReference);
    doc.setTextColor("black");
    doc.text(10, 60 + position, "Request Date:");
    doc.text(110, 60 + position, moment().format("MM/DD/yyyy"));
    doc.text(8, 80 + position, " Invoiced By:");
    doc.text(110, 80 + position, " Webjet Marketing Pty Ltd");
    doc.setFont(fontType, "bold");
    doc.setFontSize(11.5);
    doc.text(480, 80 + position, "ABN: 84 063 430 848");
    doc.setFont(fontType, "normal");
    doc.setFontSize(9.5);
    doc.setFillColor(255, 0, 0);
    doc.rect(10, 90 + position, 600, 20, "F");
    doc.setTextColor("white");

    doc.text(8, 100 + position, " Payment Details");
    doc.setFillColor(192, 192, 192);
    doc.rect(10, 110 + position, 600, 20, "F");
    doc.setTextColor("white");
    doc.text(10, 120 + position, "Price Breakdown");
  };
  const GeneratePassengerFee = (
    doc,
    invoiceData,
    paxdata,
    flightDetails,
    index,
    serviceFee
  ) => {
    doc.setTextColor("black");
    doc.setFont(fontType, "bold");
    let txtWidth = 0;
    let yPosition = 170;
    let xalignRight = 0;
   
    index = index * 150;
    
    doc.text("Passenger: " + paxdata, 10, 170 + index);

    doc.setFont(fontType, "normal");
    const spaceHeight = 15;
    yPosition = 170 + index + spaceHeight;
    const segmentHeight = 15;
    flightDetails.forEach((flightDetail, segmentIndex) => {
      yPosition += segmentHeight;
      doc.text(10, yPosition, flightDetail);
    });
    yPosition += spaceHeight;
    doc.setFont(fontType, "bold");

    const addtionalTax = "Additional AirFare and Tax ";
    doc.text(10, yPosition, addtionalTax);
    doc.setFont(fontType, "normal");
    doc.text(
      addtionalTax.length * 4.5 + 10,
      yPosition,
      "(Difference in Fare and Taxes) "
    );
    var fontSize = doc.internal.getFontSize();

    // Get page width
    var pageWidth = doc.internal.pageSize.width;
    
    const additionalCollectionalString =
      "$" +
      (invoiceData.AddtionalCollection !== "NOADC"
        ? invoiceData.AddtionalCollection.replace(/^0+/, "").replace(
            /[^\d.-]/g,
            ""
          )
        : noADC.toFixed(2))+"^";
    txtWidth =
      (doc.getStringUnitWidth(additionalCollectionalString) * fontSize) /
      doc.internal.scaleFactor;

    // Calculate text's x coordinate
    xalignRight = pageWidth - txtWidth;
    doc.text(xalignRight-5, yPosition, additionalCollectionalString);

    yPosition += spaceHeight;
    doc.setFont(fontType, "bold");
    const airlineChangeTax = "Airline Change Fee ";
    doc.text(10, yPosition, airlineChangeTax);
    doc.setFont(fontType, "normal");
    doc.text(
      airlineChangeTax.length * 4.7 + 10,
      yPosition,
      "(Airline imposed fee)"
    );

   
    invoiceChangeFeeAmt =
      invoiceData.ChangeFeeAmount != null
        ? invoiceData.ChangeFeeAmount.replace("@", ".")
        : 0.0.toFixed(2);

    const invoiceAmountString = "$" + invoiceChangeFeeAmt + "^";
    txtWidth =
      (doc.getStringUnitWidth(invoiceAmountString) * fontSize) /
      doc.internal.scaleFactor;

    // Calculate text's x coordinate
    xalignRight = pageWidth - txtWidth;
   
    invoiceChangeFeeAmt = invoiceData.ChangeFeeIsSelected
      ? invoiceAmountString
      : "$0.00";

    doc.text(xalignRight-5, yPosition, invoiceAmountString);

    yPosition += spaceHeight;
    if (serviceFee !== "" && invoiceData.ServiceFeeIsSelected) {
      const serviceFeeString = "$" + serviceFee + "*";
      txtWidth =
        (doc.getStringUnitWidth(serviceFeeString) * fontSize) /
        doc.internal.scaleFactor;
      xalignRight = pageWidth - txtWidth;

      doc.text(10, yPosition, "Webjet Service Fee");
      doc.text(xalignRight - 5, yPosition, serviceFeeString);
    }

    //doc.text(10, yPosition, "0123456790123456789012345789012345679012345678901234578901234567901234567890123457890123456790123456789012345789");
    yPosition += spaceHeight;
    doc.setLineWidth(0.5);
    doc.line(10, yPosition, 600, yPosition);

    doc.setDrawColor("gray");
  };

  const GenerateFooterWithGSTServiceFee = (
    doc,
    index,
    total,
    totalGST,
    footerIndex,
    serviceFeeVal,
    serviceGSTFee,
    travelType
  ) => {
    let yPosition = 360;
    let txtWidth = 0;
    let xalignRight = 0;
    console.log(serviceFeeVal);
    var fontSize = doc.internal.getFontSize();

    // Get page width
    var pageWidth = doc.internal.pageSize.width;
    if (serviceGSTFee !== "" && serviceFeeVal) {
      yPosition = 420;
    }
    if (index > 0 && index <=1) {
      yPosition = yPosition + 150;
    }
    else if (index >= 1) {
      yPosition = yPosition + 420;
     
    }

    let spaceHeight = 20;
    yPosition += spaceHeight;
    doc.text(10, yPosition, "Total amount excluding GST (If Applicable)");
    const totalWithGSTString = "$" + total.toFixed(2) + "*";
    txtWidth =
      (doc.getStringUnitWidth(totalWithGSTString) * fontSize) /
      doc.internal.scaleFactor;

    // Calculate text's x coordinate
    xalignRight = pageWidth - txtWidth;
    doc.text(xalignRight-5, yPosition, totalWithGSTString);

    yPosition += spaceHeight;
    doc.setFillColor(100, 100, 100);
    doc.rect(10, yPosition, 600, 20, "F");
    doc.setTextColor("white");
    total =
      parseFloat(total) +
      parseFloat(serviceGSTFee !== "" && serviceFeeVal ? serviceGSTFee : 0);
    yPosition += 10;
    doc.text(10, yPosition, "Total Payment");
    doc.setTextColor("white");
    doc.text(xalignRight-5, yPosition, totalWithGSTString);
    doc.setFont(fontType, "bold");
    doc.setFontSize(8);
    doc.setTextColor("black");
    yPosition += spaceHeight;
    doc.text(8, yPosition, " All Prices are in Australian Dollars");
    doc.setDrawColor("gray");
    doc.setLineWidth(0.5);
    doc.setFontSize(9.5);
    yPosition += spaceHeight;
    doc.line(10, yPosition, 600, yPosition);
    doc.setFont(fontType, "normal");
    yPosition += spaceHeight;
    doc.text(8, yPosition, "  ^GST Paid to Airline on above total");
    let totalGSTString = 0;
    if (
      travelType === "Domestic"
        ? (totalGSTString = "$" + totalGST.toFixed(2))
        : (totalGSTString = "$0.00")
    )
    txtWidth =
    (doc.getStringUnitWidth(totalGSTString) * fontSize) /
    doc.internal.scaleFactor;

  // Calculate text's x coordinate
  xalignRight = pageWidth - txtWidth;
  doc.text(xalignRight-5, yPosition, totalGSTString);
      

    doc.setFont(fontType, "bold");
    yPosition += 10;
    doc.text(
      10,
      yPosition,
      "Note: If the GST applies on your ticket, then its paid directly to the Airline."
    );
    doc.setFont(fontType, "bold");
    doc.setFontSize(11);
    yPosition += spaceHeight;
    doc.text(
      150,
      yPosition,
      " Thank you for your booking. We hope you enjoy your travels."
    );
    doc.setFont(fontType, "normal");
    yPosition += spaceHeight;
    doc.text(230, yPosition, " Customer Service Centre");
  };

  const GenerateTotalWithFooter = (
    doc,
    serviceFeeVal,
    index,
    total,
    totalGST,
    serviceGSTFee,
    travelType
  ) => {
    let yPosition = 300;
    let txtWidth = 0;
    let xalignRight = 0;

    if (index > 0 && index <=1) {
      yPosition = yPosition + 150;
    }
    else  if (index > 1 ) {
      yPosition = yPosition + 420;      
    } 
    console.log(index);
    let spaceHeight = 20;
    var fontSize = doc.internal.getFontSize();

    // Get page width
    var pageWidth = doc.internal.pageSize.width;
    yPosition += spaceHeight;
    doc.setFont(fontType, "bold");
    doc.text(
      10,
      yPosition,
      " Total (Inclusive of any applicable Taxes and Charges)"
    );
    const totalString = "$" + total.toFixed(2);
    txtWidth =
      (doc.getStringUnitWidth(totalString) * fontSize) /
      doc.internal.scaleFactor;

    // Calculate text's x coordinate
    xalignRight = pageWidth - txtWidth;
    doc.setFont(fontType, "normal");
    doc.text(xalignRight-5, yPosition, totalString);

    yPosition += 15;
    doc.setFont(fontType, "normal");
    doc.text(10, yPosition, "*This item includes GST paid to Webjet");
    yPosition += spaceHeight;

    doc.setDrawColor("Gray");
    doc.setLineWidth(0.5);
    doc.line(10, yPosition, 600, yPosition);
    doc.setDrawColor(192, 192, 192);

    yPosition += spaceHeight;
    doc.setFont(fontType, "normal");
    if (serviceGSTFee !== "" && serviceFeeVal) {
      doc.setFont(fontType, "bold");
      doc.text(10, yPosition, " Goods and Services Tax (GST)");
      yPosition += 10;

      doc.setFont(fontType, "normal");
      doc.text(10, yPosition, " GST paid to Webjet on above total");
      const serviceGSTFeeString = "$" + serviceGSTFee;
      txtWidth =
        (doc.getStringUnitWidth(serviceGSTFeeString) * fontSize) /
        doc.internal.scaleFactor;

      // Calculate text's x coordinate
      xalignRight = pageWidth - txtWidth;
      doc.text(xalignRight-5, yPosition, serviceGSTFeeString);

      yPosition += spaceHeight;
      doc.setDrawColor("Gray");
      doc.setLineWidth(0.5);
      doc.line(10, yPosition, 600, yPosition);
    }
    let footerIndex = 20;
    if (
      serviceGSTFee !== "" && serviceFeeVal ? footerIndex : (footerIndex = 0)
    );
    GenerateFooterWithGSTServiceFee(
      doc,
      index,
      total,
      totalGST,
      footerIndex,
      serviceFeeVal,
      serviceGSTFee,
      travelType
    );
  };

  GenerateHeader(doc, invoiceDatas);
  let serviceFeeVal = false;
  let travelType = "Domestic";
  invoiceDatas.forEach((e, i) => {
    e.Segments.forEach((segment) => {
      flightDetails.push(
        "Flight-" +
          j +
          " " +
          moment(segment.StartDateTime).format("DD/MM/yyyy HH:mm") +
          "  " +
          segment.Carrier.Code +
          " " +
          segment.FlightNumber +
          " " +
          "(" +
          segment.BookingClass +
          ")" +
          " " +
          segment.Origin.CityCode +
          ">" +
          segment.Destination.CityCode
      );
      j = j + 1;
    });
    if (serviceFee !== "" && e.ServiceFeeIsSelected) {
      serviceFeeVal = true;
    }
    travelType = e.TravelType;
    GeneratePassengerFee(
      doc,
      e,
      e.Passenger.LastName + "/" + e.Passenger.FirstNameAndPrefix,
      flightDetails,
      i,
      serviceFee
    );
    index = i;
    flightDetails.splice(0, flightDetails.length);
    j = 1; //
    console.log(
      e.AddtionalCollection.replace(/^0+/, "").replace(/[^\d.-]/g, "")
    );
    if (
      e.AddtionalCollection !== "NOADC"
        ? (addditionalAmount = e.AddtionalCollection.replace(/^0+/, "").replace(
            /[^\d.-]/g,
            ""
          ))
        : (addditionalAmount = 0.0)
    );
    if (
      e.ChangeFeeAmount != null
        ? (changeFeeAmount = e.ChangeFeeAmount.replace("@", "."))
        : (changeFeeAmount = 0.0)
    );
     total = parseFloat(total) +
        parseFloat(addditionalAmount) +
        (e.ChangeFeeIsSelected ? parseFloat(changeFeeAmount) : 0.0);
    total = parseFloat(total) + parseFloat(!serviceFeeVal ? 0.0 : serviceFee);
    console.log(total);
    totalGST = parseFloat(totalGST) + parseFloat(e.GSTAmount);
  });

  GenerateTotalWithFooter(
    doc,
    serviceFeeVal,
    index,
    total,
    totalGST,
    serviceGSTFee,
    travelType
  );

  doc.save(fileName);
  // window.open(fileName);
};
export default WebJetInvoicePDF;
